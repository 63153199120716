import React from 'react';
import ReactDOM from 'react-dom/client';
import { ReactTyped } from "react-typed";
import axios from 'axios';

class Blog extends React.Component {
    render() {
        return (
            <div className='commingsoon'>
                <span>
                    <ReactTyped 
                        strings={["به زودی"]} typeSpeed={100} showCursor={false} startDelay= {1000} backSpeed={100} loop
                        // style={{whiteSpace: 'pre-line' , color: props.color}}
                    />
                </span>
            </div>
        );
    }
}

export default Blog