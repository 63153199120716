import React, { createRef, useRef } from 'react';
import ReactDOM from 'react-dom/client';
import { ReactTyped } from "react-typed";
import SignatureCanvas from 'react-signature-canvas'
import axios from 'axios';

import Cookies from 'universal-cookie';

import '../../../styles/Tpanel/Profile.css'
import '../../../styles/Tpanel/Profile_v2.css'
import Loading from '../../../Loading/Loading';
import { Config } from '../../../config';


class User extends React.Component {
    constructor(props) {
        super(props)
        this.state = {cookies: new Cookies() , refreshDoc: false , loadingPage: false , loadingSuccessDialog: "" , loadingErrorDialog: "" , catchedData: false , smsSent: false , mode: "info" , username: "" , firstname: "" , lastname: "" , email: "" , phone: "" , password: "" , verified: ""};
    }

    rerender() {
        this.setState({refreshDoc: false , loadingPage: false , loadingSuccessDialog: "" , loadingErrorDialog: "" , catchedData: false , smsSent: false , mode: "info" , username: "" , firstname: "" , lastname: "" , email: "" , phone: "" , password: "" , verified: ""});
    }
    getData() {
        axios.get(
            Config.BASE_URL + "/user/userprofile/",
            {
                crossDomain: true,
                withCredentials: false,
                headers: {
                    'authorization': this.state.cookies.get("user-token"),
                    "Access-Control-Allow-Origin": "*"
                }
            }
        ).then(res => {
            this.setState({catchedData: true , username: res.data.username , firstname: res.data.first_name , lastname: res.data.last_name , email: res.data.email , phone: res.data.phone_number , password: res.data.password , verified: res.data.is_verified});
        }).catch(error => {
            console.log(error);
        });
    }

    loadingSuccess(dialog) {
        this.setState({loadingSuccessDialog : dialog});
        if(document.getElementsByClassName("loading--logo").length > 0) document.getElementsByClassName("loading--logo")[0].style.display = 'none';
        if(document.getElementsByClassName("loading--success").length > 0)  document.getElementsByClassName("loading--success")[0].style.display = 'flex';
        setTimeout(() => {
            this.loadingReset();
        }, "1000");
    }

    loadingError(dialog) {
        this.setState({loadingErrorDialog : dialog});
        if(document.getElementsByClassName("loading--logo").length > 0) document.getElementsByClassName("loading--logo")[0].style.display = 'none';
        if(document.getElementsByClassName("loading--error").length > 0) document.getElementsByClassName("loading--error")[0].style.display = 'flex';
        setTimeout(() => {
            this.loadingReset();
        }, "1000");
    }

    loadingReset() {
        if(document.getElementsByClassName("loading--logo").length > 0) document.getElementsByClassName("loading--logo")[0].style.display = 'flex';
        if(document.getElementsByClassName("loading--error").length > 0)  document.getElementsByClassName("loading--error")[0].style.display = 'none';
        if(document.getElementsByClassName("loading--success").length > 0) document.getElementsByClassName("loading--success")[0].style.display = 'none';
        this.setState({loadingPage: false});
    }

    setMode(s) {
        setTimeout(() => {
            this.setState({mode : s});
        }, "500")
    }

    changePassword() {

    }

    smsResendButton() {
        setTimeout(() => {
            if(document.getElementsByClassName("resend").length > 0) document.getElementsByClassName("resend")[0].style.opacity = "0.5";
            setTimeout(() => {
                if(document.getElementsByClassName("resend").length > 0) document.getElementsByClassName("resend")[0].style.opacity = "1";
            }, "30000");
        }, "1000");
    }

    sendSMS() {
        this.setState({loadingPage: true});
        axios.get(
            Config.BASE_URL + "/user/verify/",
            {
                headers: {
                    'authorization': this.state.cookies.get("user-token"),
                    "Content-type": "application/json; charset=UTF-8; Access-Control-Allow-Origin: *;", 
                }
            }
        ).then(res => {
            console.log(res);
            if(res.data.status == "ok") {
                this.loadingSuccess("کد تاییدیه با موفقیت ارسال شد");
                setTimeout(() => {
                    this.setState({loadingPage: false , smsSent: true , lastTimer: Date.now()});
                    this.smsResendButton();
                }, "1000");
            }
        }).catch(error => {
            console.log(error);
            this.loadingError("در ارسال کد خطایی پیش آمده دوباره تلاش کنید");
            setTimeout(() => {
                window.location.reload();
            } , "2000")
        });
    }

    sendVerification() {
        let Code = document.getElementsByClassName("smsVerification")[0].getElementsByClassName("code")[0].value;
        this.setState({loadingPage: true});

        axios.post(
            Config.BASE_URL + "/user/verify/",
            {
                code: Code
            },
            {
                headers: {
                    'authorization': this.state.cookies.get("user-token"),
                    "Content-type": "application/json; charset=UTF-8; Access-Control-Allow-Origin: *;", 
                }
            }
        ).then(res => {
            console.log(res);
            if(res.data.status == "ok") {
                this.loadingSuccess("تایید شد");
                setTimeout(() => {
                    this.rerender();
                } , "500")
            }
        }).catch(error => {
            console.log(error);
            this.loadingError("کد معتبر نمیباشد دوباره تلاش کنید");
        });
    }

    resendSMS() {
        //30s
        if(Math.floor((Date.now() - this.state.lastTimer) / 1000) > 30) {
            if(document.getElementsByClassName("resend").length > 0) document.getElementsByClassName("resend")[0].style.borderColor = "rgb(0, 60, 0)";
            setTimeout(() => {
                if(document.getElementsByClassName("resend").length > 0) document.getElementsByClassName("resend")[0].style.borderColor = "black";
            }, '500');
            this.setState({smsSent: false});
        }
    }

    verifyphoneHandler() {
        if(this.state.verified == true) {
            return(
                <div className='verifyphone'>
                    <span className='isverified'>همراه شما به شماره ی {this.state.phone} قبلا احراز شده است</span>
                    <button className='back' onClick={() => {this.setMode("info")}}>برگشت</button>
                </div>
            );
        }
        else {
            if(this.state.smsSent == false) {

                if(this.state.loadingPage == false) {
                    setTimeout(() => {
                        this.sendSMS();
                    }, "500")
                }

                return (
                    <div className='verifyphone'>
                        <Loading display={true} successDialog={this.state.loadingSuccessDialog} errorDialog={this.state.loadingErrorDialog}/>
                    </div>
                );
            }
            else {

                let disp = (this.state.loadingPage == true ? 'none' : 'inline-block');
                return(
                    <div className='verifyphone' key={this.state.refreshDoc}>
                        <div className='smsVerification' style={{display: disp}}>
                            <span className='topic'>کد تاییدیه به شماره همراه "{this.state.phone}" ارسال شده لطفا آن را وارد کنید</span>
                            <input className='code' placeholder='کد تاییدیه'></input>
                            <button className='verify' onClick={() => {this.sendVerification()}}>تایید</button>
                            <button className='resend' onClick={() => {this.resendSMS()}}>ارسال مجدد</button>
                            <button className='back' onClick={() => {this.setMode("info")}}>برگشت</button>
                        </div>
                        <Loading display={this.state.loadingPage} successDialog={this.state.loadingSuccessDialog} errorDialog={this.state.loadingErrorDialog}/>
                    </div>
                );
            }
        }
    }

    render() {
        if(this.state.catchedData == false) {
            setTimeout(() => {
                this.getData();
            }, "500");
            return (
                <div className='profile--user'>
                    <Loading />
                </div>
            );
        }
        if(this.state.mode == "info") {
            return (
                <div className='profile--user'>
                    <div className='info'>
                        <span className='name'>نام:<span className='text'>{this.state.firstname + " " + this.state.lastname}</span></span>
                        <span className='username'>نام کاربری:<span className='text'>{this.state.username}</span></span>
                        <span className='email'>ایمیل:<span className='text'>{this.state.email}</span></span>
                        <span className='phone'>تلفن همراه:<span className='text'>{this.state.phone}</span></span>
                        <button className='leave' onClick={() => {this.state.cookies.remove("user-token");this.state.cookies.remove("username");this.state.cookies.remove("role"); setTimeout(() => {window.location.href="/";}, "300")}}>خروج از حساب</button>
                    </div>
                    <div className='edit'>
                        <button className='changepassword' onClick={() => {this.setMode("changepassword")}}>تغییر رمز کاربری</button>
                        <button className='verifyphone' onClick={() => {this.setMode("verifyphone")}}>احراز شماره همراه</button>
                        <button className='infoedit' onClick={() => {this.setMode("infoedit")}}>ویرایش اطلاعات</button>
                    </div>
                    <div className='back'>
                        <button className='back' onClick={() => {this.props.parentRefresh();}}>برگشت</button>
                    </div>
                </div>
            );
        }
        if(this.state.mode == "changepassword") {
            return(
                <div className='profile--user'>
                    <div className='changepassword'>
                        <input className='password curr' placeholder='رمز فعلی'></input>
                        <input className='password new' placeholder='رمز جدید'></input>
                        <button className='button' onClick={() => {this.changePassword();}}>تایید</button>
                        <button className='back' onClick={() => {this.setMode("info");}}>برگشت</button>
                    </div>
                </div> 
            );
        }
        if(this.state.mode == "verifyphone") {
            if(this.state.smsSent == true) this.resendSMS();
            return(
                <div className='profile--user'>
                    {this.verifyphoneHandler()}
                </div>
            );
        }
    }
}

export default User