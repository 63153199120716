import React from 'react';
import ReactDOM from 'react-dom/client';
import { ReactTyped } from "react-typed";
import axios from 'axios';

import mealcover from '../../../files/avocado_dark.png'
import Cookies from 'universal-cookie';
import Diets from './Plans/Diet/Diets';
import Trainers from './Trainee/Trainers';
import Blog from './Blog/Blog';
import Profile from './Profile/Profile';
import Loading from '../../Loading/Loading';
import Kahu from './Gymi/Kahu';

var scrollinv = null;

class Viewboard extends React.Component {
    constructor(props) {
        super(props)
    }
    openpdf() {
        // let html = ;
        // let printWindow = window.open('', '', 'height=600,width=1000,top=' + ((screen.height - 600) / 2) + ',left=' + ((screen.width - 1000) / 2));
        // printWindow.document.write(html);
        // printWindow.document.body.style.height = (participantsInfo.length * 297) + "mm";
        // printWindow.document.close();
        // printWindow.print();
    }

    render() {
        if(this.props.type == "mealmaker") {
            return (
                <div className='cpanel--viewboard'>
                    <Kahu />
                </div>
            );
        }

        window.clearInterval();
        scrollinv = null;
        if(this.props.type == "meals") {
            return (
                <div className='cpanel--viewboard'>
                    <Diets />
                </div>
            );
        }
        if(this.props.type == "trainers") {
            return (
                <div className='cpanel--viewboard'>
                    <Trainers />
                </div>
            );
        }
        if(this.props.type == "blog") {
            return (
                <div className='cpanel--viewboard'>
                    <Blog />
                </div>
            );
        }
        if(this.props.type == "profile") {
            return (
                <div className='cpanel--viewboard'>
                    <Profile />
                </div>
            );
        }
    }
}

export default Viewboard