import { useParams } from "react-router-dom";
import Forgotpassword from "./Forgotpassword";

function ForgotpasswordWithParams() {
    let param = useParams();
    console.log(param.token);
    return (
        <Forgotpassword token={param.token}/>
    );
}

export default ForgotpasswordWithParams;