import React from 'react';
import ReactDOM from 'react-dom/client';
import Cookies from 'universal-cookie';

import '../styles/Home/Menubar.css'
import '../styles/Home/Menubar_v2.css'
import logo from '../../files/banners/GYMi/LOGO/Picture3.png'
import Loading from '../Loading/Loading'

class Menubar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {cookies: new Cookies()};
        this.linkfield = this.linkfield.bind(this);
        this.fadeMenu = this.fadeMenu.bind(this);
        this.brightMenu = this.brightMenu.bind(this);
        this.dashboard = this.dashboard.bind(this);
    }

    fadeMenu() {
        var items = document.getElementsByClassName("item");
        for (var i = 0; i < 4; i++)
            items[i].style.opacity = "0.2";
    }
    brightMenu() {
        var items = document.getElementsByClassName("item");
        for (var i = 0; i < 4; i++)
            items[i].style.opacity = "1";
    }    
    logoClick() {
        document.getElementsByClassName('item logo')[0].style.opacity = "0";
        setTimeout(() => {
            document.getElementsByClassName('item logo')[0].style.opacity = "1";
        }, "300");
        setTimeout(() => {
            window.location.href = '/';
        }, "550");
    } 
    linkfield() {
        if(this.state.cookies.get('user-token') == null) {
            return(
                <div className='menubar--link' onClick={() => {window.location.href = "/login"}}><span className='text'>ورود/ثبت نام</span></div>
            );
        }
        else {
            if(this.state.cookies.get('role') == "coach" || this.state.cookies.get('role') == "Coach" || this.state.cookies.get('role') == "Coache") {
                return(
                    <div className='menubar--link' onClick={() => {window.location.href = "/Cpanel"}}><span className='text'>پنل کاربری</span></div>
                );
            }
            else {
                return(
                    <div className='menubar--link' onClick={() => {window.location.href = "/Tpanel"}}><span className='text'>پنل کاربری</span></div>
                );
            }
        }
    }
    dashboard() {
        return (
            <div className='menubar--dashboard' onMouseEnter={() => this.fadeMenu()} onMouseLeave={() => this.brightMenu()}>
                <div className='item aboutus' id='item--aboutus'><span className='text'>درباره ی ما</span></div>
                <div className='item supports' id='item--supports'><span className='text'>حمایت ها</span></div>
                <div className='item partners' id='item--partners'><span className='text'>همکاران ما</span></div>
                <div className='item logo' id='item--logo' onClick={() => this.logoClick()}><img src={logo}></img></div>
            </div>
        );
    }

    render() {
        return (
            <div className = "menubar" onMouseOver={this.fadeMenu} onMouseLeave={this.brightMenu}>
                <this.linkfield />
                <this.dashboard />
            </div>
        );
    }
}

export default Menubar;