import React from 'react';
import ReactDOM from 'react-dom/client';
import { ReactTyped } from "react-typed";
import axios from 'axios';

import logo from '../../files/logo2.png'

import Cookies from 'universal-cookie';

import '../styles/Forgot/forgot.css';
import Loading from '../Loading/Loading';
import Menubar from '../Home/Menubar';

const { Config } = require('../config');

class Forgotpassword extends React.Component { 
    constructor(props) {
        super(props)
        this.state = {cookies: new Cookies() , loadingSuccessDialog: "" , loadingErrorDialog: "" , log: "" , tokenVerified: false}
    } 

    startLoading(name) {
        document.getElementsByClassName(name)[0].style.display = 'none';
        document.getElementsByClassName(name)[1].style.display = 'flex';
        setTimeout(() => {
            document.getElementsByClassName(name)[1].style.opacity = "1";
        } , "100");
    }
    loadingSuccess(name , dialog , href) {
        let elem = document.getElementsByClassName(name)[1];
        this.setState({loadingSuccessDialog : dialog});
        elem.getElementsByClassName("loading--logo")[0].style.display = 'none';
        elem.getElementsByClassName("loading--success")[0].style.display = 'flex';

        setTimeout(() => {
            this.loadingReset(name);
            window.location.href = href;
        } , "3000");
    }
    loadingError(name , dialog , href) {
        let elem = document.getElementsByClassName(name)[1];
        this.setState({loadingErrorDialog : dialog});
        elem.getElementsByClassName("loading--logo")[0].style.display = 'none';
        elem.getElementsByClassName("loading--error")[0].style.display = 'flex';

        setTimeout(() => {
            this.loadingReset(name);
            window.location.href = href;
        }, "3000");
    }
    loadingReset(name) {
        let elem = document.getElementsByClassName(name)[1];
        elem.getElementsByClassName("loading--logo")[0].style.display = 'flex';
        elem.getElementsByClassName("loading--error")[0].style.display = 'none';
        elem.getElementsByClassName("loading--success")[0].style.display = 'none';
    }

    setPhoneNumber() {
        let phone_number = document.getElementsByClassName("phone")[1].value;
        if(phone_number.length < 10) {
            this.setState({log: "شماره همراه وارد شده موجود نمیباشد"});
            setTimeout(() => {
                this.setState({log: ""})
            }, "5000")
            return;
        }

        this.setState({log: ""});
        this.startLoading("form phone");
        axios.post(
            Config.BASE_URL + "/user/forgotaccount/",
            {
                phone_number: phone_number
            },
            {
                headers: {
                    "Content-type": "application/json; charset=UTF-8; Access-Control-Allow-Origin: *;",
                }
            }
        ).then(res => {
            this.loadingSuccess("form phone" , "لینک برای تغییر رمز عبور ارسال شد" , "/login")
        }).catch(error => {
            if(error.response.data.error == "Invalid phone number") {
                this.loadingError("form phone" , "شماره همراه وارد شده موجود نمیباشد" , "/forgot");
            }
            else {
                this.loadingError("form phone" , "خطایی پیش آمده دوباره تلاش کنید" , "/forgot")
            }
        });
    }

    tokenVerify() {
        axios.post(
            Config.BASE_URL + "/user/forgottoken/",
            {
                token: this.props.token
            },
            {
                headers: {
                    "Content-type": "application/json; charset=UTF-8; Access-Control-Allow-Origin: *;",
                }
            }
        ).then(res => {
            this.setState({tokenVerified: true});
        }).catch(error => {
            if(error.response.data.error == "Invalid token") {
                this.loadingError("form token" , "لینک معتبر نمیباشد" , "/")
            }
            else {
                this.loadingError("form token" , "خطایی پیش آمده دوباره تلاش کنید" , "/")
            }
        });
    }

    setPassword() {
        let password = document.getElementsByClassName("newpassword")[0].value;
        let passwordVerify = document.getElementsByClassName("newpassword")[1].value;
        if(password.length < 4) {
            this.setState({log: "رمز عبور نباید کمتر از ۴ حرف باشد"});
            setTimeout(() => {
                this.setState({log: ""})
            }, "5000")
            return;
        }
        if(password != passwordVerify) {
            this.setState({log: "رمز با تاییدیه آن برابر نمیباشد"});
            setTimeout(() => {
                this.setState({log: ""})
            }, "5000")
            return;
        }

        this.setState({log: ""});
        this.startLoading("form password");
        axios.post(
            Config.BASE_URL + "/user/resetpassword/",
            {   
                token: this.props.token,
                password: password
            },
            {
                headers: {
                    "Content-type": "application/json; charset=UTF-8; Access-Control-Allow-Origin: *;",
                } 
            }
        ).then(res => {
            this.loadingSuccess("form password" , "رمز عبور با موفقیت تغییر یافت" , "/login");
        }).catch(error => {
            if(error.response.data.error == "Invalid token") {
                this.errorDialog("form password" , "لینک معتبر نمیباشد" , "/")
            }
            else {
                this.loadingError("form password" , "خطایی پیش آمده دوباره تلاش کنید" , "/")
            }
        });
    }

    render() {
        if(this.props.token == null) {
            return(
                <div className='forgot'>
                    <Menubar />
                    <div className='form phone'>
                        <span className='topic'>شماره همراه خود را وارد کنید</span>
                        <input className='phone' placeholder='شماره همراه'></input>
                        <button className='send' onClick={() => this.setPhoneNumber()}>تایید</button>
                        <span className='log--error'>
                            <ReactTyped 
                                strings={[this.state.log]} typeSpeed={25} showCursor={false} startDelay={100} loop={false}
                                style={{whiteSpace: 'pre-line' , color: "rgb(200,0,0)"}}
                            />
                        </span>
                    </div>
                    <div className='form phone loading' style={{display: "none"}}>
                        <Loading successDialog={this.state.loadingSuccessDialog} errorDialog={this.state.loadingErrorDialog}/>
                    </div>
                </div>
            );
        }

        if(this.state.tokenVerified == false) {
            return(
                <div className='forgot' onLoad={() => this.tokenVerify()}>
                    <Menubar />
                    <div className='form token' style={{display: "none"}}></div>
                    <div className='form token loading'>
                        <Loading successDialog={this.state.loadingSuccessDialog} errorDialog={this.state.loadingErrorDialog}/>
                    </div>
                </div>
            );
        }

        else {
            return (
                <div className='forgot'>
                    <Menubar />
                    <div className='form password'>
                        <span className='topic'>رمز جدید را وارد کنید</span>
                        <input className='newpassword' placeholder='رمز عبور'></input>
                        <input className='newpassword verify' placeholder='تایید رمز'></input>
                        <button className='send' onClick={() => this.setPassword()}>تایید</button>
                        <span className='log--error'>
                            <ReactTyped 
                                strings={[this.state.log]} typeSpeed={25} showCursor={false} startDelay={100} loop={false}
                                style={{whiteSpace: 'pre-line' , color: "rgb(200,0,0)"}}
                            />
                        </span>
                    </div>
                    <div className='form password loaing' style={{display:"none"}}>
                        <Loading successDialog={this.state.loadingSuccessDialog} errorDialog={this.state.loadingErrorDialog}/>
                    </div>
                </div>
            );
        }
    }
}

export default Forgotpassword