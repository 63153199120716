import React from 'react';
import ReactDOM from 'react-dom/client';
import { ReactTyped } from "react-typed";
import axios from 'axios';
import Loading from '../../../../Loading/Loading';

import '../../../../styles/Cpanel/Diet.css'
import '../../../../styles/Cpanel/Diet_v2.css'
import Cookies from 'universal-cookie';
import daypageCover from "../../../../../files/pdf_daypage.jpg"

const { Config } = require('../../../../config');

const mealsIndex = ["وعده اول","وعده دوم","وعده سوم", "وعده چهارم","وعده پنجم","وعده ششم","وعده هفتم","وعده هشتم"];

class Diet extends React.Component {
    constructor(props) {
        super(props);

        this.state = {cookies: new Cookies() , lastId: null , dietName: "" , dietMeals: null , catchData: false}
        this.firstpage = this.firstpage.bind(this);
        this.get_diet_data = this.get_diet_data.bind(this);
        this.otherpage = this.otherpage.bind(this);
        this.mealhandler = this.mealhandler.bind(this);
        this.handlediet = this.handlediet.bind(this);
    }

    get_diet_data() {
        let response , name , meals = [];
        axios.get(
            Config.BASE_URL + "/diets/diet/"+this.props.id,
            {   
                headers: {
                    'authorization': this.state.cookies.get("user-token"),
                    "Content-type": "application/json; charset=UTF-8; Access-Control-Allow-Origin: *;"
                }
            }
        ).then(res => {
            console.log(res);
            response = res.data;
            // console.log(response);

            name = response.name;
            meals = response.meals;
            console.log(meals);
            this.setState({dietName: name , dietMeals: meals , catchData: true});
        })
    }

    mealhandler(index) {
        let topmm = "";
        if(index < 2) {
            topmm = (index == 0 ? "85mm" : "190mm");
        }
        else {
            let nindex = (index - 2) % 2;
            topmm = (nindex == 0 ? "30mm" : "154mm");
        }

        let ingredients = this.state.dietMeals[index].ingredients.split('،');
        let len = ingredients.length;
        let ingredientsItems = [];
        for (let i = 0; i < len; i = i + 1) {
            ingredientsItems.push(<div className="ingredients--item"><span className="text">{ingredients[i].trim()}</span></div>);
        }
        return (
            <div className={"mealbox " + index.toString()} style={{top: topmm}}>
                <div className="index"><span>{mealsIndex[index]}</span></div>
                <div className="meal">
                    <div className="meal--name"><span className="text">{this.state.dietMeals[index].food_name}</span></div>
                    <div className="meal--info">
                        <div className="headers">
                            <div className="ingredients"><span className="text">مواد مورد نیاز</span></div>
                            <div className="recip"><span className="text">طرز تهیه</span></div>
                        </div>
                        <div className="info">
                            <div className="ingredients">
                                {ingredientsItems}
                            </div>
                            <div className="recip"><span className="text">{this.state.dietMeals[index].recip}</span></div>
                        </div>
                    </div>
                    <div className="meal--analysis">
                        <div className="calories"><span className="tag">کالری:</span><span className="text">{this.state.dietMeals[index].calories}</span></div>
                        <div className="protein"><span className="tag">پروتیین:</span><span className="text">{this.state.dietMeals[index].protein}</span></div>
                        <div className="carbo"><span className="tag">کربوهیدرات:</span><span className="text">{this.state.dietMeals[index].carbohydrate}</span></div>
                        <div className="fat"><span className="tag">چربی:</span><span className="text">{this.state.dietMeals[index].fat}</span></div>
                    </div>
                </div>
            </div>
        );
    }
    firstpage() {
        let meals = [];
        if(this.state.dietMeals.length > 0) {
            meals.push(this.mealhandler(0));
        }
        if(this.state.dietMeals.length > 1) {
            meals.push(this.mealhandler(1));
        }
        return(
            <div className='pdf--page' style={{top: "0mm"}}>
                <img src={daypageCover}></img>

                <div className="diet--info">
                    <div className="diet--name"><span className="text">{this.state.dietName}</span></div>
                    <div className="coach--name"><span className="text">مربی</span></div>
                    <div className="coach--sign">امضا</div>
                    <div className='details'>
                        <div className="user--name"><span className="text">هنرجو</span></div>
                        <div className="meals--count"><span className="text">{this.state.dietMeals.length} وعده</span></div>
                        <div className="date--created"><span className="text">تاریخ ثبت</span></div>
                    </div>
                </div>

                {meals}
            </div>
        );
    }
    otherpage(p) {
        let topmm = p * 294 + p * 3;
        topmm = topmm.toString() + "mm";
        console.log(topmm);
        let meals = [];
        if(this.state.dietMeals.length > 2 * p) {
            meals.push(this.mealhandler(2 * p));
        }
        if(this.state.dietMeals.length > 2 * p + 1) {
            meals.push(this.mealhandler(2 * p + 1));
        }
        return (
            <div className={'pdf--page ' + p.toString()} style={{top: topmm}}>
                <img src={daypageCover}></img>

                {meals}
            </div>
        );
    }

    handlediet() {
        let diet = [];
        diet.push(this.firstpage());
        if(this.state.dietMeals.length > 2) {
            diet.push(this.otherpage(1));
        }
        if(this.state.dietMeals.length > 4) {
            diet.push(this.otherpage(2));
        }
        if(this.state.dietMeals.length > 6) {
            diet.push(this.otherpage(3));
        }
        return diet;
    }

    render() {
        // if(this.state.lastId == null || this.state.lastId != this.props.id) {
        //     this.setState({lastId: this.props.id , catchData: false , streamDialog: null , streamLoading: false , messages:[]});
        // }
        if(this.props.id == null) {
            return;
        }
        if(this.state.lastId == null || this.props.id != this.state.lastId) {
            this.setState({lastId: this.props.id , catchData: false, dietName: "" , dietMeals: []});
            return;
        }
        if(this.state.catchData == false) {
            setTimeout(() => {
                this.get_diet_data();
            }, 500)
            return(
                <Loading />
            );
        }

        return (
            <div className='diet'>
                <div className="pdf--body" id="pdf--body">
                    {this.handlediet()}
                </div>
            </div>
        );
    }
}

export default Diet