import React from 'react';
import ReactDOM from 'react-dom/client';
import axios from 'axios';
import Cookies from 'universal-cookie';
import { ReactTyped } from "react-typed";

import '../styles/Signup/Signup.css'
import '../styles/Signup/Signup_v2.css'
import Loading from '../Loading/Loading';

import '../styles/DietRegister/DietRegister.css';
import Menubar from '../Home/Menubar';

const { Config } = require('../config');


class DietRegister extends React.Component {
    constructor(props) {
        super(props);
        this.state = {cookies: new Cookies() , refreshDoc: false , verify: false , userMode: "login" , requestSent: false , log: "" , loadingSuccessDialog: "" , loadingErrorDialog: "" , userPhone: ""};
        this.startLoading = this.startLoading.bind(this);
        this.loginLoadingSuccess = this.loginLoadingSuccess.bind(this);
        this.loginLoadingError = this.loginLoadingError.bind(this);
        this.loadingReset = this.loadingReset.bind(this);
        this.sendLoginData = this.sendLoginData.bind(this);
        this.registerDiet = this.registerDiet.bind(this);
        this.changeUserMode = this.changeUserMode.bind(this);
        this.dietLoadingSuccess = this.dietLoadingSuccess.bind(this);
        this.dietLoadingError = this.dietLoadingError.bind(this);
    }

    changeUserMode(mode) {
        this.setState({userMode : mode});
    }


    startLoading() {
        document.getElementsByClassName("form")[0].style.display = 'none';
        document.getElementsByClassName("form")[1].style.display = 'flex';
        setTimeout(() => {
            document.getElementsByClassName("form")[1].style.opacity = "1";
        } , "100");
    }
    loginLoadingSuccess(dialog) {
        let elem = document.getElementsByClassName("form")[1];
        this.setState({loadingSuccessDialog : dialog});
        elem.getElementsByClassName("loading--logo")[0].style.display = 'none';
        elem.getElementsByClassName("loading--success")[0].style.display = 'flex';
        setTimeout(() => {
            this.loadingReset();
        } , "1000");
    }
    loginLoadingError(dialog) {
        let elem = document.getElementsByClassName("form")[1];
        this.setState({loadingErrorDialog : dialog});
        elem.getElementsByClassName("loading--logo")[0].style.display = 'none';
        elem.getElementsByClassName("loading--error")[0].style.display = 'flex';
        setTimeout(() => {
            this.loadingReset();
            window.location.reload();
        }, "1000");
    }
    dietLoadingSuccess(dialog) {
        this.setState({loadingSuccessDialog : dialog , loadingErrorDialog: "" , requestSent: true});
        document.getElementsByClassName("loading--logo")[0].style.display = 'none';
        document.getElementsByClassName("loading--success")[0].style.display = 'flex';
        setTimeout(() => {
            this.loadingReset();
            window.location.href = "/";
        } , "1000");
    }
    dietLoadingError(dialog) {
        this.setState({loadingErrorDialog : dialog , loadingSuccessDialog: "" , requestSent: true});
        document.getElementsByClassName("loading--logo")[0].style.display = 'none';
        document.getElementsByClassName("loading--error")[0].style.display = 'flex';
        setTimeout(() => {
            this.loadingReset();
            // window.location.href = "/";
        }, "1000");
    }


    loadingReset() {
        document.getElementsByClassName("loading--logo")[0].style.display = 'flex';
        document.getElementsByClassName("loading--error")[0].style.display = 'none';
        document.getElementsByClassName("loading--success")[0].style.display = 'none';
    }

    registerDiet(t , role) {
        if(role == "coach" || role == "Coach") {
            this.dietLoadingError("برای حساب شما دسترسی به این رژیم مقدور نمیباشد");
            return;
        }

        axios.post(
            Config.BASE_URL + "/diets/share",
            {
                diet_id: this.props.dietId
            },
            {
                headers: {
                    'authorization': t,
                    "Content-type": "application/json; charset=UTF-8; Access-Control-Allow-Origin: *;"
                }
            }
        ).then(res => {
            console.log(res);
            this.dietLoadingSuccess("رژیم به پنل شما اضافه شد");
            
        }).catch(error => {
            console.log(error);
            this.dietLoadingError("در ثبت رژیم برای حساب شما مشکلی پیش آمده دوباره تلاش کنید");
        });
    }
            
    sendLoginData() {
        let Username = document.getElementsByClassName("username")[0].value;
        let Password = document.getElementsByClassName("password")[0].value;

        this.startLoading();
        axios.post(
            Config.BASE_URL + "/user/login/",
            {
              username: Username,
              password: Password
            },
            {
              headers: {
                "Content-type": "application/json; charset=UTF-8; Access-Control-Allow-Origin: *;",
              }
            }
        ).then(res => {
            console.log(res);
            this.state.cookies.set('user-token' , "Token " + res.data.token , {path: '/' , sameSite:'lax'});
            this.state.cookies.set('username' , Username, {path: '/' , sameSite:'lax'});
            this.state.cookies.set('role' , res.data.role, {path: '/' , sameSite:'lax'});
            console.log(this.state.cookies.get("role"));
            setTimeout(() => {
                this.setState({refreshDoc: true});
            }, "1000");
            this.loginLoadingSuccess("خوش آمدید");
        }).catch(error => {
            console.log(error);
            this.loginLoadingError("خطایی پیش آمده دوباره تلاش کنید!");
        });
    }

    forbidden() {
        this.state.cookies.remove("user-token");
        this.state.cookies.remove("username");
        this.state.cookies.remove("role");
        window.location.href = '/login';
    }
    roleCheck() {
        let role = "none";
        axios.get(
            Config.BASE_URL + "/user/getrole/",
            {
              headers: {
                'authorization': this.state.cookies.get("user-token"),
                "Content-type": "application/json; charset=UTF-8; Access-Control-Allow-Origin: *;"
              }
            }
        ).then(res => {
            role = res.data.role;
            console.log(role);
            if(role != "User") {
                this.dietLoadingError("برای حساب شما دسترسی به این رژیم مقدور نمیباشد");

                setTimeout(() => {
                    this.forbidden();
                }, "1000")
            }
            else {
                this.setState({verify: true});
            }
        }).catch(error => {
            console.log(error);
            this.dietLoadingError("در ثبت رژیم برای حساب شما مشکلی پیش آمده دوباره تلاش کنید");

            setTimeout(() => {
                this.forbidden();
            }, "1000")
        });
    }

    render() {
        if(this.state.cookies.get('user-token') != null || this.state.refreshDoc == true) {
            if(this.state.requestSent == false) {
                if(this.state.verify == false) {
                    this.roleCheck();
                }
                else {
                    setTimeout(() => {
                        this.registerDiet(this.state.cookies.get('user-token') , this.state.cookies.get('role'));
                    }, "500");
                }
            }

            return(
                <div className='dietregister'>
                    <Loading successDialog={this.state.loadingSuccessDialog} errorDialog={this.state.loadingErrorDialog} />
                </div>
            );
        }
        else {
            if(this.state.userMode == "signup") {
                return (
                    <div className='dietregister'>
                        <div className = "signup">
                            <Menubar />
                            <div className='form'>
                                <div className='form--topic'>
                                    <span className='signup--topic'>ابتدا ثبت نام کرده و سپس مجددا از طریق لینک یا QR به اشتراک گذاشته شده جهت افزودن رژیم به پنل خود اقدام نمایید</span>
                                </div>
                                <button className='signup--button' onClick={() => {window.location.href = "/signup"}}>ثبت نام</button>
                                <a className='login' onClick={() => this.changeUserMode("login")}>ثبت نام کردید؟</a>
                            </div>
                        </div>
                    </div>
                );
            }
            else {
                return (
                    <div className='dietregister'>
                        <div className = "login">
                            <Menubar />
                            <div className='form'>
                                <div className='form--topic'>
                                    <span className='login--topic'>ورود</span>
                                </div>
                                <input className='username' placeholder='نام کاربری'></input>
                                <input className='password' placeholder='رمز کاربری'></input>
                                <button className='login--button' onClick={() => this.sendLoginData()}>تایید</button>
                                <a className='loginform--signup' onClick={() => this.changeUserMode("signup")}>ثبت نام نکردید؟</a>
                                <a href='/forgot' className='loginform--forgot'>فراموش کردید؟</a>
                                <span className='log--error'>
                                    <ReactTyped 
                                        strings={[this.state.log]} typeSpeed={25} showCursor={false} startDelay={100} loop={false}
                                        style={{whiteSpace: 'pre-line' , color: "rgb(200,0,0)"}}
                                    />
                                </span>
                            </div>
                            <div className='form loading' style={{display:'none'}}>
                                <Loading successDialog={this.state.loadingSuccessDialog} errorDialog={this.state.loadingErrorDialog}/>
                            </div>
                        </div>
                    </div>
                );
            }
        }
    }
}

export default DietRegister;