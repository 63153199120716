// cropImage.js
const getCroppedImg = (imageSrc, pixelCrop) => {
    return new Promise((resolve, reject) => {
        const image = new Image();
        image.src = imageSrc;
        image.onload = () => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');

            canvas.width = pixelCrop.width;
            canvas.height = pixelCrop.height;

            ctx.drawImage(
                image,
                pixelCrop.x,
                pixelCrop.y,
                pixelCrop.width,
                pixelCrop.height,
                0,
                0,
                pixelCrop.width,
                pixelCrop.height
            );

            // Convert to circular image
            const circularCanvas = document.createElement('canvas');
            const circularCtx = circularCanvas.getContext('2d');
            circularCanvas.width = pixelCrop.width;
            circularCanvas.height = pixelCrop.height;

            circularCtx.beginPath();
            circularCtx.arc(
                circularCanvas.width / 2,
                circularCanvas.height / 2,
                circularCanvas.width / 2,
                0,
                Math.PI * 2,
                true
            );
            circularCtx.closePath();
            circularCtx.clip();

            circularCtx.drawImage(canvas, 0, 0);

            // Convert to base64
            resolve(circularCanvas.toDataURL('image/jpeg'));
        };

        image.onerror = (error) => {
            reject(error);
        };
    });
};

export default getCroppedImg;
