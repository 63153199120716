import React from 'react';
import ReactDOM from 'react-dom/client';
import { ReactTyped } from "react-typed";
import axios from 'axios';
import Loading from '../../../Loading/Loading';


import Cookies from 'universal-cookie';

const { Config } = require('../../../config');

let scrollinv = null;

class Chat extends React.Component {
    constructor(props) {
        super(props);

        this.state = {cookies: new Cookies() , lastId: null , catchData: false , streamDialog: null , streamLoading: false , messages:[]}
        this.update_display = this.update_display.bind(this);
        this.shortcut = this.shortcut.bind(this);
        this.send_message = this.send_message.bind(this);
        this.chatHistory = this.chatHistory.bind(this);
        this.streamChat = this.streamChat.bind(this);
        this.get_all_messages = this.get_all_messages.bind(this);
        this.endConversation = this.endConversation.bind(this);
        this.delConversation = this.delConversation.bind(this);
        this.loadingSuccess = this.loadingSuccess.bind(this);
        this.loadingError = this.loadingError.bind(this);
    }

    endConvLoading() {
        document.getElementsByClassName("modal--endverify")[0].style.display = 'none';
        document.getElementsByClassName("modal--endverify")[1].style.display = 'flex';
        setTimeout(() => {
            document.getElementsByClassName("modal--endverify")[1].style.opacity = "1";
        } , "100");
    }
    delConvLoading() {
        document.getElementsByClassName("modal--delverify")[0].style.display = 'none';
        document.getElementsByClassName("modal--delverify")[1].style.display = 'flex';
        setTimeout(() => {
            document.getElementsByClassName("modal--delverify")[1].style.opacity = "1";
        } , "100");
    }

    loadingSuccess(t) {
        document.getElementsByClassName("loading--logo")[0].style.display = 'none';
        document.getElementsByClassName("loading--success")[0].style.display = 'flex';
        setTimeout(() => {
            this.props.parentRefresh();
            this.loadingReset();
        } , "1000");
    }
    loadingError(t) {
        document.getElementsByClassName("loading--logo")[0].style.display = 'none';
        document.getElementsByClassName("loading--error")[0].style.display = 'flex';
        setTimeout(() => {
            this.setState({catchData: false});
            this.loadingReset();
        }, "1000");
    }
    loadingReset() {
        document.getElementsByClassName("loading--logo")[0].style.display = 'flex';
        document.getElementsByClassName("loading--error")[0].style.display = 'none';
        document.getElementsByClassName("loading--success")[0].style.display = 'none';
    }

    endConversation() {
        this.endConvLoading();
        setTimeout(() => {
            let s = document.getElementsByClassName("modal--endverify")[0].getElementsByClassName("name")[0].value;
            if(s.length == 0) return;
            axios.post(
                Config.BASE_URL + "/chat/conversation/finish/"+this.props.id,
                {
                "name": s
                },
                {  
                headers: {
                    'authorization': this.state.cookies.get("user-token"),
                    "Content-type": "application/json; charset=UTF-8; Access-Control-Allow-Origin: *;"
                }
                }
            ).then(res => {
                console.log(res);
                let response = res.data.response;
                setTimeout(() => {
                    // this?.setState({catchData: false});
                    this.loadingSuccess(0);
                }, "100");
                return;
            }).catch(function (error) {
                console.log(error);
                this.loadingError(0);
            });
        }, 300)
    }
    delConversation() {
        this.delConvLoading();
        setTimeout(() => {
            axios.post(
                Config.BASE_URL + "/chat/conversation/delete",
                {
                    "conversation_id": this.props.id
                },
                {  
                    headers: {
                        'authorization': this.state.cookies.get("user-token"),
                        "Content-type": "application/json; charset=UTF-8; Access-Control-Allow-Origin: *;"
                    }
                }
            ).then(res => {
                console.log(res);
                let response = res.data.response;
                setTimeout(() => {
                    this.loadingSuccess(1);
                }, "100");
                return;
            }).catch(function (error) {
                console.log(error);
                this.loadingError(1);
            });
        }, 300)
    }

    openEndConvModal() {
        document.getElementsByClassName("endconv--modal")[0].style.display = "flex";
        document.getElementsByClassName("endconv--modal")[0].style.visibility = "visible";
        setTimeout(() => {
            document.getElementsByClassName("modal--endverify")[0].style.opacity = "1";
        } , "100");
    }
    openDelConvModal() {
        document.getElementsByClassName("delconv--modal")[0].style.display = "flex";
        document.getElementsByClassName("delconv--modal")[0].style.visibility = "visible";
        setTimeout(() => {
            document.getElementsByClassName("modal--delverify")[0].style.opacity = "1";
        } , "100");
    }
    get_all_messages() {
        let response = [] , messages = [];
        axios.get(
            Config.BASE_URL + "/chat/conversation/"+this.props.id,
            // { crossDomain: true },
            {   
                crossDomain: true,
                withCredentials: false,
                headers: {
                    'authorization': this.state.cookies.get("user-token"),
                    "Access-Control-Allow-Origin": "*"
                }
            }
        ).then(res => {
            console.log(res);
            response = res.data;
            for(let i = 0; i < response.length; i += 1) {
                let message = [];
                message.push(response[i].chat_text);
                if(response[i].from_user == true) message.push("from");
                else message.push("to");
                messages.push(message);
            }
            this.setState({messages: messages , catchData: true});
        })

        // console.log("hello");
        // this.setState({messages: [["salam" , "from"] , ["salam chikar dari?" , "to"] , ["hichi hamintori" , "from"] , ["ok pas" , "to"]]});
        // this.setState({catchData: true});
    }
    chatHistory() {
        let chats=[];
        let len = this.state.messages.length;
        console.log(len);
        for(let i = 0; i < len; i += 1) {
            if(this.state.messages[i][1] == "from") {
                chats.push( <div className='rightchat'>
                                <div className='textbox'>
                                    <span className='text'>{this.state.messages[i][0]}</span>
                                </div>
                            </div>
                );
            }
            else {
                chats.push( <div className='leftchat'>
                                <div className='textbox'>
                                    <span className='text'>{this.state.messages[i][0]}</span>
                                </div>
                            </div>
                );
            }
        }
        return chats;
    }
    streamChat() {
        this.autoscroll()
        if(this.state.streamDialog == null) {
            if(this.state.streamLoading == true) {
                return( <div className='streambox' >
                            <div className='textbox'>
                                <span className='text' onChange={() => this.autoscroll()}>
                                    <ReactTyped 
                                        strings={["..."]} typeSpeed={50} showCursor={false} startDelay={0} loop={true}
                                        style={{whiteSpace: 'pre-line' , color: "green"}}
                                    />
                                </span>
                            </div>
                        </div>
                );
            }
            else {
                return (
                    <div className='streambox'></div>
                );
            }
        }
        return(
            <div className='streambox' >
                <div className='textbox'>
                    <span className='text' onChange={() => this.autoscroll()}>
                        <ReactTyped 
                            strings={[this.state.streamDialog]} typeSpeed={10} showCursor={false} startDelay={0}
                            style={{whiteSpace: 'pre-line' , color: "white"}} onBegin={() => this.autoscrollStart()} onComplete={()=> this.autoscrollEnd()}
                        />
                    </span>
                </div>
            </div>
        );
    }

    send_message(s) {
        console.log("message:");
        console.log(s);
        axios.post(
            Config.BASE_URL + "/chat/v2/conversation/send/"+this.props.id,
            {
              "text": s
            },
            {  
              headers: {
                'authorization': this.state.cookies.get("user-token"),
                "Content-type": "application/json; charset=UTF-8; Access-Control-Allow-Origin: *;"
              }
            }
        ).then(res => {
            console.log(res.data.response);
            let response = res.data.response;
            setTimeout(() => {
                this.setState({streamDialog: response});
                this.setState({streamLoading: false});
            }, "600");
            return;
        }).catch(function (error) {
            console.log(error);
        });
    }
    shortcut(e) {
        if (e.key == "Enter" && !e.shiftKey) {
            e.preventDefault();
            this.update_display();
        }
    }
    autoscroll() {
        if(document.getElementsByClassName('static--chats').length == 0) return;
        console.log("scroll");
        var elem = document.getElementsByClassName('static--chats')[0];
        elem.scrollTo({
            top: elem.scrollHeight,
            behavior: 'smooth'
        });
    }
    autoscrollStart() {
        // console.log("shuru");
        scrollinv = setInterval(() => this.autoscroll() , "300");
    }
    autoscrollEnd() {
        // console.log("payan");
        clearInterval(scrollinv);
        scrollinv = null;
    }
    update_display() {
        if(this.state.streamLoading == true) return
        // input value
        let s = document.getElementById('inputbox').value;
        s = s.trim();
        // reset input box
        document.getElementById('inputbox').value = "";

        // input button style
        if(s.length == 0) {
            document.getElementById('inputbutton').style.color = "rgb(150 , 0 , 0)";
            setTimeout(() => {
                document.getElementById('inputbutton').style.color = "white";
            }, "500");
            return;
        }
        document.getElementById('inputbutton').style.color = "rgb(0 , 150 , 0)";
        setTimeout(() => {
            document.getElementById('inputbutton').style.color = "white";
        }, "500");


        let updated = this.state.messages;
        if(this.state.streamDialog != null) {
            updated.push([document.getElementsByClassName('streambox')[0].textContent , "to"]);
            // let last = "<div class='leftchat'><div class='textbox'><span class='text'>" + document.getElementsByClassName('streambox')[0].textContent + "</span></div></div>";
            // document.getElementsByClassName('chats--end')[0].insertAdjacentHTML("beforebegin" , last);
        }
        // let elem = document.getElementsByClassName('streambox')[0];
        // document.getElementsByClassName('streambox')[0].remove();
        
        updated.push([s , "from"]);
        this.setState({messages: updated});
        // let last = "<div class='rightchat'><div class='textbox'><span class='text'>" + s + "</span></div></div>";
        // document.getElementsByClassName('chats--end')[0].insertAdjacentHTML("beforebegin" , last);
        // this.autoscroll();
        
        this.setState({streamLoading: true});
        this.setState({streamDialog: null});
        setTimeout(() => {
            this.autoscroll();
            this.send_message(s);
        }, 200)
        

        // update states
        // setTimeout(() => {
        //     this.setState({index: this.state.index + 1});
        //     this.setState({dialog: response});
        //     document.getElementsByClassName('chats--end')[0].insertAdjacentElement("afterend" , elem);
        // }, "600");
    }

    render() {
        if(this.state.lastId == null || this.state.lastId != this.props.id) {
            this.setState({lastId: this.props.id , catchData: false , streamDialog: null , streamLoading: false , messages:[]});
        }
        if(this.props.id == null) {
            return;
        }
        if(this.state.catchData == false) {
            setTimeout(() => {
                this.get_all_messages();
            }, 500)
            return(
                <Loading />
            );
        }
        return (
            <div className='chatbox'>
                <div className='static--chats' onChange={() => this.autoscroll()}>
                    {this.chatHistory()}
                    <div className='chats--end'></div>
                    {this.streamChat()}
                    {this.autoscroll()}
                </div>
                
                <div className='chatbox--input'>
                    <button className='inputbutton' id='inputbutton' onClick ={() => this.update_display()}>></button>
                    <textarea id='inputbox' cols="30" rows="5" onKeyDown={(e) => this.shortcut(e)}></textarea>
                    <div className='status'>
                        <button className='status--done' onClick={() => this.openEndConvModal()}>ثبت رژیم</button>
                        <button className='status--delete' onClick={() => this.openDelConvModal()}>حذف گفتگو</button>
                    </div>
                </div>
                <div className='endconv--modal'>
                    <div className='modal--endverify'>
                        <input className='name' placeholder='عنوان رژیم را وارد کنید'></input>
                        <div className='topic'><span className='txt'>از صحت اطلاعات رژیم اطمینان دارید؟</span></div>
                        <button className='agree' onClick={() => this.endConversation()}>اطمینان دارم</button>
                        <button className='disagree'>برگشت</button>
                    </div>
                    <div className='modal--endverify loading' style={{display:'none'}}>
                        <Loading successDialog={"رژیم با موفقیت ساخته شد میتوانید از پنل کاربری مشاهده کنید"} errorDialog={"خطایی پیش آمده دوباره تلاش کنید!"}/>
                    </div>
                </div>
                <div className='delconv--modal'>
                    <div className='modal--delverify'>
                        <div className='topic'><span className='txt'>از حذف این گفتگو اطمینان دارید؟</span></div>
                        <button className='agree' onClick={() => this.delConversation()}>اطمینان دارم</button>
                        <button className='disagree'>برگشت</button>
                    </div>
                    <div className='modal--delverify loading' style={{display:'none'}}>
                        <Loading successDialog={'گفتگو با موفقیت حذف شد'} errorDialog={'خطایی پیش آمد دوباره تلاش کنید'}/>
                    </div>
                </div>
            </div>
        );
    }
}

export default Chat