import React from 'react';
import ReactDOM from 'react-dom/client';
import './pages/styles/index.css';
import reportWebVitals from './reportWebVitals';

import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
<React.StrictMode>
  <App />
</React.StrictMode>
);

reportWebVitals();