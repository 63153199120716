import React, { createRef, useRef } from 'react';
import ReactDOM from 'react-dom/client';
import { ReactTyped } from "react-typed";
import SignatureCanvas from 'react-signature-canvas'
import axios from 'axios';

import Cookies from 'universal-cookie';

import '../../../styles/Cpanel/Profile.css'
import '../../../styles/Cpanel/Profile_v2.css'
import Loading from '../../../Loading/Loading';
import { Config } from '../../../config';
import User from './User';
import Sign from './Sign';


class Profile extends React.Component {
    constructor(props) {
        super(props)
        this.state = {cookies: new Cookies() , mode: null};

        this.setMode = this.setMode.bind(this);
        this.refresh = this.refresh.bind(this);
    }
    
    refresh() {
        setTimeout(() => {
            this.setState({mode: null});
        }, "1000");
    }

    setMode(s) {
        setTimeout(() => {
            this.setState({mode: s});
        }, "1000");
    }

    render() {
        if(this.state.mode == null) {
            return (
                <div className='profile'>
                    <button className='user' onClick={() => this.setMode('user')}>مشخصات کاربری</button>
                    <button className='sign' onClick={() => this.setMode('sign')}>ثبت امضا</button>
                    <button className='activation' onClick={() => this.setMode('activation')}>کد فعالسازی</button>
                    <button className='requests' onClick={() => this.setMode('requests')}>درخواست ها</button>
                </div>
            );
        }
        if(this.state.mode == 'user') {
            return(
                <div className='profile'>
                    <User parentRefresh={this.refresh} />
                </div>
            );
        }
        if(this.state.mode == 'sign') {
            return(
                <div className='profile'>
                    <Sign parentRefresh={this.refresh} />
                </div>
            );
        }
        if(this.state.mode == 'activation') {
            return(
                <div className='profile'>
                    
                </div>
            );
        }
        if(this.state.mode == 'requests') {
            return(
                <div className='profile'>
                    
                </div>
            );
        }
    }
}

export default Profile