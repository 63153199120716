import React from 'react';
import ReactDOM from 'react-dom/client';
import axios from 'axios';
import Cookies from 'universal-cookie';
import { ReactTyped } from "react-typed";

import '../styles/Signup/Signup.css'
import '../styles/Signup/Signup_v2.css'
import logo from '../../files/banners/GYMi/LOGO/Picture3.png'
import cover from '../../files/homecover.jpg'
import Loading from '../Loading/Loading';
import Verification from '../Verification/Verification';
import Menubar from '../Home/Menubar';

const { Config } = require('../config');


class Signup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {cookies: new Cookies() , catchData: false , usernames: [] , userMode: null , log: "" , loadingSuccessDialog: "" , loadingErrorDialog: "" , verificationToken: "" , userPhone: ""};
        this.signupLoading = this.signupLoading.bind(this);
        this.loadingSuccess = this.loadingSuccess.bind(this);
        this.loadingError = this.loadingError.bind(this);
        this.loadingReset = this.loadingReset.bind(this);
        this.senddata = this.senddata.bind(this);
        this.getUsernamesData = this.getUsernamesData.bind(this);
        this.checkUsername = this.checkUsername.bind(this);
    }

    setUserMode(mode) {
        let revMode = (mode == "coach" ? "trainee" : "coach");
        document.getElementsByClassName("mode " + revMode)[0].style.width = "0%";
        document.getElementsByClassName("mode " + revMode)[0].style.display = "none";
        document.getElementsByClassName("mode " + mode)[0].style.width = "100%";
        document.getElementsByClassName("mode " + mode)[0].style.color = "rgb(0,60,0)";
        document.getElementsByClassName("mode " + mode)[0].style.borderColor = "rgb(185, 55, 20)";
        document.getElementsByClassName("mode " + mode)[0].style.borderRadius = "20px";
        setTimeout(() => {
            this.setState({userMode: mode})
        }, "800");
    }

    signupLoading() {
        document.getElementsByClassName("form")[0].style.display = 'none';
        document.getElementsByClassName("form")[1].style.display = 'flex';
        setTimeout(() => {
            document.getElementsByClassName("form")[1].style.opacity = "1";
        } , "100");
    }
    loadingSuccess(dialog) {
        let elem = document.getElementsByClassName("form")[1];
        this.setState({loadingSuccessDialog : dialog});
        elem.getElementsByClassName("loading--logo")[0].style.display = 'none';
        elem.getElementsByClassName("loading--success")[0].style.display = 'flex';
        setTimeout(() => {
            this.loadingReset();
            this.verificationLoad();
        } , "1000");
    }
    loadingError(dialog) {
        let elem = document.getElementsByClassName("form")[1];
        this.setState({loadingErrorDialog : dialog});
        elem.getElementsByClassName("loading--logo")[0].style.display = 'none';
        elem.getElementsByClassName("loading--error")[0].style.display = 'flex';
        setTimeout(() => {
            this.loadingReset();
            window.location.href = '/signup';
        }, "1000");
    }
    loadingReset() {
        let elem = document.getElementsByClassName("form")[1];
        elem.getElementsByClassName("loading--logo")[0].style.display = 'flex';
        elem.getElementsByClassName("loading--error")[0].style.display = 'none';
        elem.getElementsByClassName("loading--success")[0].style.display = 'none';
    }

    verificationLoad() {
        document.getElementsByClassName("form")[1].style.opacity = '0';
        setTimeout(() => {
            document.getElementsByClassName("form")[1].style.display = 'none';
            document.getElementsByClassName("form")[2].style.display = 'flex';
            setTimeout(() => {
                document.getElementsByClassName("form")[2].style.opacity = "1";
            } , "100");
        }, "100");
    }

    senddata() {
        let Username = document.getElementsByClassName("username")[0].value;
        let Password = document.getElementsByClassName("password")[0].value;
        let Email = document.getElementsByClassName("email")[0].value;
        let Phone = document.getElementsByClassName("phone")[0].value;
        let Firstname = document.getElementsByClassName("firstname")[0].value;
        let Lastname = document.getElementsByClassName("lastname")[0].value;
        Username = Username.toLowerCase();
        var pattern=/[\x00-\x7F]+/;
        if(Username.length < 5) {
            this.setState({log: "نام کاربری نباید کمتر از ۵ حرف باشد"});
            return;
        }
        if(!pattern.test(Username)) {
            this.setState({log: "نام کاربری باید از حروف لاتین تشکیل شود"});
            return;
        }
        if(Password.length < 4) {
            this.setState({log: "رمز عبور نباید کمتر از ۴ حرف باشد"});
            return;
        }
        if(Password != document.getElementsByClassName("password--verify")[0].value) {
            this.setState({log: "رمز با تاییدیه آن برابر نمیباشد"});
            return;
        }
        if(Phone.length < 10) {
            this.setState({log: "شماره همراه معتبر نمیباشد"});
            return;
        }
        if(!pattern.test(Username) || (!pattern.test(Email) && Email.length > 0) || !pattern.test(Phone) || !pattern.test(Password)) {
            this.setState({log: "جز در نام و نام خانوادگی باید از حروف و ارقام لاتین استفاده شود"});
            return ;
        }


        this.signupLoading();
        // if(this.state.userMode == "coach") {
            axios.post(
                Config.BASE_URL + "/user/signup/",
                {
                    username: Username,
                    password: Password,
                    email: Email,
                    phone_number : Phone,
                    first_name:Firstname,
                    last_name:Lastname
                },
                {
                    headers: {
                        "Content-type": "application/json; charset=UTF-8; Access-Control-Allow-Origin: *;", 
                    }
                }
            ).then(res => {
                console.log(res);
                if(res.data.status == "ok") {
                    this.setState({verificationToken: res.data.token , userPhone: Phone});
                    this.loadingSuccess("حساب کاربری با موفقیت ساخته شد");
                }
            }).catch(error => {
                if(error.response.data.error == "username already exists") {
                    this.loadingError("نام کاربری تکراری می باشد");
                }
                else if(error.response.data.error == "phone already exists") {
                    this.loadingError("شماره همراه تکراری می باشد");
                }
                else {
                    this.loadingError("خطایی پیش آمده دوباره تلاش کنید!");
                }
            })
        // }
        // if(this.state.userMode == "trainee") {
            //...
        // }
    }   

    getUsernamesData() {
        let allusernames = [];
        axios.get(
            Config.BASE_URL + "/user/allusers/",
            {
                headers: {
                    "Content-type": "application/json; charset=UTF-8; Access-Control-Allow-Origin: *;", 
                }
            }
        ).then(res => {
            console.log(res);
            allusernames = res.data;
            for (let i = 0; i < allusernames.length; i = i + 1) {
                allusernames[i] = allusernames[i].toLowerCase();
            }
            this.setState({catchData: true , usernames: allusernames});
        }).catch(error => {
            console.log(error);
        });
    }
    checkUsername() {
        let u = document.getElementsByClassName("username")[0].value;
        u = u.toLowerCase();
        var pattern=/[\x00-\x7F]+/;
        if(u.length < 5 || this.state.usernames.includes(u) || !pattern.test(u)) {
            document.getElementsByClassName("username")[0].style.borderColor = "rgb(100,0,0)";
        }
        else {
            document.getElementsByClassName("username")[0].style.borderColor = "rgb(0,100,0)";
        }
    }
    checkPassword() {
        let p1 = document.getElementsByClassName("password")[0].value;
        let p2 = document.getElementsByClassName("password--verify")[0].value;
        var pattern=/[\x00-\x7F]+/;
        if(p1.length < 4 || !pattern.test(p1)) {
            document.getElementsByClassName("password")[0].style.borderColor = "rgb(100,0,0)";
            document.getElementsByClassName("password--verify")[0].style.borderColor = "rgb(100,0,0)";
        }
        else if(p1 != p2) {
            document.getElementsByClassName("password")[0].style.borderColor = "rgb(0,100,0)";
            document.getElementsByClassName("password--verify")[0].style.borderColor = "rgb(100,0,0)";
        }
        else {
            document.getElementsByClassName("password")[0].style.borderColor = "rgb(0,100,0)";
            document.getElementsByClassName("password--verify")[0].style.borderColor = "rgb(0,100,0)";
        }
    }

    render() {
        if(this.state.cookies.get('user-token') != null) {
            window.location.href = '/';
            return;
        }
        if(this.state.catchData == false) {
            this.getUsernamesData();
        }
        if(this.state.userMode == null) {
            return(
                <div className='signup'>
                    <Menubar />
                    <div className='form mode'>
                        <button className='mode coach' onClick={() => this.setUserMode("coach")}>{"مربی"}</button>
                        <button className='mode trainee' onClick={() => this.setUserMode("trainee")}>{"هنرجو"}</button>
                    </div>
                </div>
            );
        }
        if(this.state.userMode == "coach") {
            return (
                <div className = "signup">
                    <Menubar />
                    <div className='form coach'>
                        <div className='form--topic'>
                            <span className='signup--topic'>ثبت نام</span>
                        </div>
                        <input className='username' placeholder='نام کاربری' onChange={() => this.checkUsername()}></input>
                        <input className='firstname' placeholder='نام'></input>
                        <input className='lastname' placeholder='نام خانوادگی'></input>
                        <input className='phone' placeholder='شماره همراه'></input>
                        <input className='email' placeholder='(اختیاری)ایمیل'></input>
                        <input className='password' placeholder='رمز کاربری' onChange={() => this.checkPassword()}></input>
                        <input className='password--verify' placeholder='تایید رمز کاربری' onChange={() => this.checkPassword()}></input>
                        <button className='signup--button' onClick={() => this.senddata("coach")}>تایید</button>
                        <a href="/login" className='login'>ثبت نام کردید؟ وارد شوید</a>
                        <span className='log--error'>
                            <ReactTyped 
                                strings={[this.state.log]} typeSpeed={25} showCursor={false} startDelay={100} loop={false}
                                style={{whiteSpace: 'pre-line' , color: "rgb(200,0,0)"}}
                            />
                        </span>
                    </div>
                    <div className='form coach loading' style={{display:'none'}}>
                        <Loading successDialog={this.state.loadingSuccessDialog} errorDialog={this.state.loadingErrorDialog}/>
                    </div>
                    <div className='form coach verification' style={{display: 'none'}}>
                        <Verification href={"/login"} token={"Token " + this.state.verificationToken} phone={this.state.userPhone}/>
                    </div>
                </div>
            );
        }
        if(this.state.userMode == "trainee") {
            return(
                <div className='signup'>
                    <Menubar />
                    <div className='form trainee'>
                        <span className='signup--topic'>ثبت نام</span>
                        {/* <input className='coachcode' placeholder='کد فعالسازی مربی'></input> */}
                        <input className='username' placeholder='نام کاربری' onChange={() => this.checkUsername()}></input>
                        <input className='firstname' placeholder='نام'></input>
                        <input className='lastname' placeholder='نام خانوادگی'></input>
                        <input className='phone' placeholder='شماره همراه'></input>
                        <input className='email' placeholder='(اختیاری)ایمیل'></input>
                        <input className='password' placeholder='رمز کاربری' onChange={() => this.checkPassword()}></input>
                        <input className='password--verify' placeholder='تایید رمز کاربری' onChange={() => this.checkPassword()}></input>
                        <button className='signup--button' onClick={() => this.senddata("trainee")}>تایید</button>
                        <a href="/login" className='login'>ثبت نام کردید؟ وارد شوید</a>
                        <span className='log--error'>
                            <ReactTyped 
                                strings={[this.state.log]} typeSpeed={25} showCursor={false} startDelay={100} loop={false}
                                style={{whiteSpace: 'pre-line' , color: "rgb(200,0,0)"}}
                            />
                        </span>
                    </div>
                    <div className='form trainee loading' style={{display:'none'}}>
                        <Loading successDialog={this.state.loadingSuccessDialog} errorDialog={this.state.loadingErrorDialog}/>
                    </div>
                    <div className='form trainee verification' style={{display: 'none'}}>
                        <Verification href={"/login"} token={"Token " + this.state.verificationToken} phone={this.state.userPhone}/>
                    </div>
                </div>
            );
        }
    }
}

export default Signup;